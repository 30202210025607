<template>
  <div class="container">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>提现列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span>手机号：</span>
      <el-input style="width: 200px" v-model="formData.phone" placeholder="请输入手机号" />
      <span>开始时间：</span>
      <el-date-picker v-model="formData.beginTime" type="datetime" placeholder="选择日期时间" align="right"
        value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions">
      </el-date-picker>
      <span>结束时间：</span>
      <el-date-picker v-model="formData.endTime" type="datetime" placeholder="选择日期时间" align="right"
        value-format="yyyy-MM-dd HH:mm:ss" @change="endTimeChange" :picker-options="pickerOptions2">
      </el-date-picker>
      <span>处理时间：</span>
      <el-date-picker v-model="timeObj" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <br/>
      <br/>
      <br/>
      <div>
        <span>状态：</span>
      <el-select v-model="formData.status" placeholder="请选择">
        <el-option v-for="item in optionsStatus" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" class="btn" @click="getTableList()">查询</el-button>
      <el-button type="primary" class="btn" @click="exportExcel()">导出报表</el-button>
      </div>
    </div>

    <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%">
      <el-table-column prop="cashId" label="提现ID"> </el-table-column>
      <el-table-column prop="trueName" label="提现人"> </el-table-column>
      <el-table-column prop="idCard" label="身份证"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="addTime" label="提现时间"> </el-table-column>
      <el-table-column prop="money" label="提现金额"> </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>
      <el-table-column prop="strStatus" label="状态"> </el-table-column>
      <el-table-column label="操作" width="300px">
        <template slot-scope="{ row }">
          <el-popconfirm v-if="row.status == 0 &&
            $store.state.powerList.indexOf('cash:info:list:edit') !== -1
            " title="确认通过该提现申请吗？" @confirm="toSuccess(row)">
            <el-button type="primary" size="mini" slot="reference">
              通过
            </el-button>
          </el-popconfirm>
          <el-button type="primary" size="mini" slot="reference" style="margin-left: 10px" v-if="row.status == 0 &&
            $store.state.powerList.indexOf('cash:info:list:edit') !== -1
            " @click="showApply(row)">
            不通过
          </el-button>
          <el-button type="primary" size="mini" slot="reference" style="margin-left: 10px" v-if="$store.state.powerList.indexOf('user:bank:list:select') !== -1
            " @click="viewData(row)">
            查看签约信
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="formData.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="formData.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="formData.total">
    </el-pagination>

    <el-dialog :close-on-click-modal="false" title="不通过原因" :visible.sync="dialogVisible" width="50%">
      <el-form ref="form" label-width="100px" class="grade_list">
        <el-form-item label="不通过原因">
          <el-input type="textarea" :rows="4" v-model="editForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideVisible()">取 消</el-button>
        <el-button type="primary" @click="toApply(1)">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看签约信" :visible.sync="viewClassADialogVisible" width="30%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="真实姓名：">
          <span>{{ viewInfoData.trueName }}</span>
        </el-form-item>
        <el-form-item label="会员手机号：">
          <span>{{ viewInfoData.userPhone }}</span>
        </el-form-item>
        <el-form-item label="银行名称：">
          <span>{{ viewInfoData.bankName }}</span>
        </el-form-item>
        <el-form-item label="卡号：">
          <span>{{ viewInfoData.bankNo }}</span>
        </el-form-item>
        <el-form-item label="手机号：">
          <span>{{ viewInfoData.phone }}</span>
        </el-form-item>
        <el-form-item label="身份证正面：">
          <el-image style="width: 100px; height: 100px" :src="viewInfoData.idCardZ"
            :preview-src-list="[viewInfoData.idCardZ]"></el-image>
        </el-form-item>
        <el-form-item label="身份证反面：">
          <el-image style="width: 100px; height: 100px" :src="viewInfoData.idCardF"
            :preview-src-list="[viewInfoData.idCardF]"></el-image>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  withdrawalRecordApi,
  withdrawalRefuse,
  withdrawalTure,
  exportExcel,
  getUserBankList,
} from "../../api/Finance.js";

import { sendFile } from "../../api/sendFile";
export default {
  name: "withdrawalRecord",
  data() {
    return {
      options: [],
      timeObj: [],
      optionsStatus: [
        {
          label: "全部",
          value: -1,
        },
        {
          label: "申请中",
          value: 0,
        },
        {
          label: "已通过",
          value: 1,
        },
        {
          label: "已拒绝",
          value: 2,
        },
        {
          label: "付款中",
          value: 3,
        },
      ],
      formData: {
        phone: "",
        total: 0,
        status: 0,
        pageSize: 10,
        currentPage: 1,
        beginTime: "",
        endTime: "",
        handleBeginTime:'',
        handleEndTime:'',
      },
      editForm: {
        cashId: "",
        remark: "",
      },
      tableData: [],
      multipleSelection: [],
      dialogVisible: false,
      dialogVisible2: false,
      pickerOptions: {
        disabledDate: (time) => {
          let beginTime = time.getTime();
          let endTime = new Date(this.formData.endTime).getTime();
          return beginTime > endTime;
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      pickerOptions2: {
        disabledDate: (time) => {
          let endTime = time.getTime();
          let beginTime = new Date(this.formData.beginTime).getTime();
          return beginTime > endTime;
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      viewClassADialogVisible: false,
      viewInfoData: "",
    };
  },
  created() {
    this.getTableList();
  },
  methods: {
    async exportExcel() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let date = +new Date();
      console.log(date, "date");
      const { data } = await exportExcel(this.formData);
      console.log(data, "data");
      let url = window.URL.createObjectURL(data); //转换文件流未url
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `万旅网小程序V2.0提现列表_${date}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      loading.close();
    },
    async getTableList() {
      this.formData.handleBeginTime =  this?.timeObj ? this?.timeObj[0] : ''
      this.formData.handleEndTime = this?.timeObj? this?.timeObj[1] : ''
      const { data } = await withdrawalRecordApi(this.formData);
      this.tableData = data.list;
      this.formData.total = data.pagination.total;
      this.formData.pageSize = data.pagination.pageSize;
      this.formData.currentPage = data.pagination.current;
    },
    async toSuccess(row) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data } = await withdrawalTure({
        cashId: row.cashId,
      });
      loading.close();
      if (data.code == 0) {
        this.getTableList();
        this.$message.success(data.msg);

      } else {
        this.$message.error(data.msg);
      }
    },
    showApply(row) {
      this.editForm.cashId = row.cashId;
      this.editForm.remark = "";
      this.dialogVisible = true;
    },
    async toApply(status) {
      if (this.editForm.remark == "") {
        return this.$message.error("请填写审核不通过原因");
      }

      const { data } = await withdrawalRefuse(this.editForm);
      console.log("编辑回调", data);
      if (data.code == 0) {
        this.hideVisible();
        this.getTableList();

        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    hideVisible() {
      this.dialogVisible = false;
      this.dialogVisible2 = false;
    },
    handleSizeChange(num) {
      console.log(num);
      this.formData.pageSize = num;
      this.getTableList();
    },
    handleCurrentChange(num) {
      console.log("currentPage", num);
      this.formData.currentPage = num;
      this.getTableList();
    },
    endTimeChange(endDate) {
      let beginTime = new Date(this.formData.beginTime).getTime();
      let endTime = new Date(this.formData.endTime).getTime();
      console.log(beginTime, endTime);
      if (beginTime && endTime < beginTime) {
        this.formData.endTime = "";
        return this.$message.error("结束日期不能小于开始日期!");
      }
    },
    async viewData(row) {
      const { data } = await getUserBankList({
        userId: row.userId,
        pageSize: 1,
        currentPage: 1,
      });
      if (data.code == 0) {
        this.viewClassADialogVisible = true;
        this.viewInfoData = data.list[0];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  .add {
    margin-top: 20px;
  }

  .el-table {
    margin-top: 50px;
  }

  .search_box {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .btn {
      margin: 0 20px;
    }

    span {
      display: inline-block;
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .el-pagination {
    margin-top: 50px;
    float: right;
  }

  /deep/.el-dialog__header {
    border-bottom: 1px solid #f1f1f1;
  }

  /deep/.el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }

  .btn_box {
    margin-top: 24px;
  }

  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }

  /deep/.popconfirm {
    margin-right: 10px;
  }
}
</style>
